import React, { Ref } from 'react';
import { Input } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { SearchOutlined } from '@ant-design/icons';

// import type { SearchProps } from 'antd/es/input/Search';
// const { Search } = Input;
// const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);

const SearchBox: React.FC<{ inputRef?: Ref<any>, placeholder?: string, size?: SizeType, onChange?: (v: any) => void, onClear?: () => void, onClose?: () => void }> = ({ inputRef, placeholder = '', size = 'middle', onChange = () => { }, onClear = () => { }, onClose = () => { } }) => (
    <Input
        size={size}
        placeholder={placeholder}
        allowClear
        onChange={(e) => { onChange(e.target.value); if (e.target.value === '') onClear(); }}
        style={{ width: 250 }}
        ref={inputRef}
        prefix={<SearchOutlined />}
        onKeyDown={e => e.key === 'Escape' && onClose()}
    />
);

export default SearchBox;