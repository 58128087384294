import { Observable, Observer } from "rxjs";
import { DataType, DeviceData } from "./d";
import _ from 'lodash'
import config from "../../lib/config";

/*
const getData: (i: number) => DataType[] = (i) => {
  return [
    {
      key: "2",
      device: "Behandlungsraum 01",
      status: -1,
      description: "",
      children: [
        {
          key: "12",
          device: "Dürr Quattro Tandem Praxis-Kompressor",
          status: (i * 3) % 4,
          ts: new Date().getTime() - 5 * 60 * 1000,
          description: "",
        },
        {
          key: "13",
          device: "Dürr CA 4 Amalgamabscheider",
          status: (i * 9) % 4,
          ts: new Date().getTime() - 2 * 60 * 1000,
          description:
            "My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.",
          children: [
            {
              key: "32",
              device: "Dürr Quattro Tandem Praxis-Kompressor",
              status: (i * 21) % 4,
              ts: new Date().getTime() - 5 * 60 * 1000,
              description: "",
            },
            {
              key: "33",
              device: "Dürr CA 4 Amalgamabscheider",
              status: (i + 17) % 4,
              ts: new Date().getTime() - 2 * 60 * 1000,
              description:
                "My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.",
            },
          ],
        },
      ],
    },
    {
      key: "12",
      device: "Behandlungsraum 02",
      status: -1,
      description:
        "My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.",
      children: [
        {
          key: "112",
          device: "Dürr Quattro Tandem Praxis-Kompressor",
          status: i % 4,
          ts: new Date().getTime() - 1 * 60 * 1000,
          description: "",
        },
        {
          key: "113",
          device: "Dürr Quattro Tandem Praxis-Kompressor",
          status: (i * 12) % 4,
          ts: new Date().getTime() - 9 * 60 * 1000,
          description: "",
        },
        {
          key: "114",
          device: "Dürr CA 4 Amalgamabscheider",
          status: (i * 13) % 4,
          ts: new Date().getTime() - 8 * 60 * 1000,
          description:
            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
        },
      ],
    },
    {
      key: "3",
      device: "Dampsoft Server",
      status: (i + 2) % 4,
      description: "",
    },
    {
      key: "1",
      device: "W&H Lisa 17 2019",
      status: (i + 3) % 4,
      ts: new Date().getTime() - 5 * 60 * 1000,
      description:
        "My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.",
    },
  ];
};
*/

const deviceData2DataType = (keyPrefix: string = '') => (x: DeviceData, idx: number) => ({
  key: `${keyPrefix}_${x.serial}_${idx}`,
  device: x.geraetbezeichnung && x.geraetbezeichnung !== 'null' ? x.geraetbezeichnung : `${x.manufacturer} SN: ${x.serial}`,
  status: x.status,
  ts: new Date(x.updatedAt).getTime(),
  description: '',
})

const groupMe = (d: DeviceData[], groupAttrs: string[], keyPrefix: string = ''): DataType[] => {
  if (groupAttrs.length) {
    const [attr, ...rest] = groupAttrs
    return _(d)
      .groupBy(x => Boolean(_.get(x, attr)) ? _.get(x, attr) : 'unbekannt')
      .map((value, key) => ({ key: `${keyPrefix}_${key}`, device: key, status: -1, children: groupMe(value, rest, `${keyPrefix}_${key}`) } as DataType))
      .value()
  } else {
    return d.map(deviceData2DataType(keyPrefix))
  }
}

let groupByAttrs: string[] = []
let searchValue: string = ''

const getDeviceData = () => fetch(`${config.apiEndpoint}/api/devices`, { credentials: 'include' }).then(r => r.ok ? r.json() : []).then((d: DeviceData[]): DataType[] => {
  let _d = d ?? []

  if (searchValue) {
    _d = d.filter(x => `${x.geraetbezeichnung}_${x.kundennummer}_${x.serial}`.toLowerCase().indexOf(searchValue) >= 0)
  }

  return groupMe(_d, groupByAttrs)
})

// make an observeable
// This function runs when subscribe() is called
function sequenceSubscriber(observer: Observer<DataType[]>) {
  getDeviceData().then(x => observer.next(x))
  // observer.next(getData(i));

  const updateInterval = setInterval(() => {
    getDeviceData().then(x => observer.next(x))
    // observer.next(getData(i));
  }, 10000);

  // observer.complete();

  return {
    unsubscribe() {
      clearInterval(updateInterval)
    }
  };
}

// Create a new Observable that will deliver the above sequence
export const sequence$ = new Observable(sequenceSubscriber);
// feeded by a websocket

// provide settings
export const setGroupAttrs: (attrs: string[]) => void = (attrs) => {
  groupByAttrs = attrs
};

export const setSearchValue: (value: string) => void = (value) => {
  searchValue = value.toLowerCase()
}

// signon via MS SSO
export const signOn = (token: string) => fetch(`${config.apiEndpoint}/api/signon`, { credentials: 'include', headers: { 'Authorization': `Bearer ${token}` } }).then(r => {
  if (!r.ok) {
    throw new Error('SignOn failed.')
  }
  return r.ok
})