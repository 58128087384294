import { useContext } from "react";
import { TeamsFxContext } from "./Context";
import DeviceStatusTable from "./iot/DeviceStatusTable";

export default function Tab() {
  const { themeString } = useContext(TeamsFxContext);
  return (
    <div
      className={themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}
    >
      <DeviceStatusTable size={'middle'} />
    </div>
  );
}
